//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "Unregister",
  data() {
    return {
      extUser: {
        avatar: '',
        name: ''
      },
      consumer: {

      },
      show: false
    };
  },

  mounted() {
    window.document.title = "客户详情";
    this.getMmeberInfo()
  },
  activated() {
    window.document.title = "客户详情";
  },
  created() {
    window.document.title = "客户详情";

  },
  methods: {
    getMmeberInfo() {
      this.axios.get('/' + this.$ajaxPrefix.consumer + '/v1/wx/cp/externalcontact/userByUserId?userId=' + this.$route.query.euid).then(res => {

        if (res && res.data && res.data.data) {
          const temp = res.data.data
          if (temp.consumer&&temp.consumer.bpid&&temp.baseInfo.isVisible=='yes') {
            this.$router.replace("/extcustomer/list?bpid=" + temp.consumer.bpid + "&name=" + temp.consumer.name + "&euid=" + this.$route.query.euid);
          } else if (temp.extUser) {
            this.extUser = temp.extUser
            this.show = true
          }

        } else {

        }
      });
    },

  },
};
